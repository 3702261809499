import React from 'react';
import * as styles from './logo-garden.module.css';

interface LogoProps {
    logoUrl: string;
    logoDescription: string;
    logoTarget: 'same_window' | 'new_window';
    logoLink?: string;
    lessThan4?: boolean;
    lessThan6?: boolean;
}

const Logo: React.FC<LogoProps> = ({
    logoUrl,
    logoDescription,
    logoTarget,
    logoLink,
    lessThan4,
    lessThan6,
}) => {
    const target = logoTarget === 'same_window' ? '_self' : '_blank';

    return (
        <div
            className={`${styles.logoContainer} ${
                logoLink ? styles.zoomEffect : ''
            } ${lessThan4 ? styles.largeLogosize : ''} ${
                lessThan6 ? styles.logoalignment : ''
            }`}
        >
            {!logoLink ? (
                <img src={logoUrl} alt={logoDescription} />
            ) : (
                <a href={logoLink} target={target}>
                    <img src={logoUrl} alt={logoDescription} />
                </a>
            )}
        </div>
    );
};

export default Logo;
