// extracted by mini-css-extract-plugin
export var additionalMargin = "logo-garden-module--additionalMargin--ad295";
export var additionalSpace = "logo-garden-module--additionalSpace--724b3";
export var additionalpadding = "logo-garden-module--additionalpadding--2cc93";
export var ctaContainer = "logo-garden-module--ctaContainer--b1a50";
export var description = "logo-garden-module--description--071e4";
export var floatingDescBox = "logo-garden-module--floatingDescBox--bb01b";
export var headline = "logo-garden-module--headline--04d34";
export var largeLogosize = "logo-garden-module--largeLogosize--302f4";
export var lessThan6 = "logo-garden-module--lessThan6--b1738";
export var logoContainer = "logo-garden-module--logoContainer--5428d";
export var logoalignment = "logo-garden-module--logoalignment--57a65";
export var logosWrapper = "logo-garden-module--logosWrapper--513c6";
export var showLessArrow = "logo-garden-module--showLessArrow--56912";
export var wrapper = "logo-garden-module--wrapper--95b50";
export var zoomEffect = "logo-garden-module--zoomEffect--78819";